import { SignedIn, SignedOut } from "@clerk/remix";
import { redirect, type LoaderFunctionArgs } from "@remix-run/cloudflare";
import { createClerkClient } from "@clerk/remix/api.server";
import { getAuth } from "@clerk/remix/ssr.server";

import { DOWNLOAD_APP_URL, NEW_ORGANIZATION_URL, SIGN_UP_URL } from "@/urls";
import { Navigate } from "@remix-run/react";

export const loader = async (args: LoaderFunctionArgs) => {
  const { userId } = await getAuth(args);

  if (userId) {
    const clerk = createClerkClient({
      secretKey: args.context.cloudflare.env.CLERK_SECRET_KEY,
    });
    const organizationMemberships =
      await clerk.users.getOrganizationMembershipList({ userId });
    if (organizationMemberships.totalCount === 0) {
      return redirect(NEW_ORGANIZATION_URL);
    } else {
      return redirect(DOWNLOAD_APP_URL);
    }
  } else {
    return redirect(SIGN_UP_URL);
  }
};

const Index = () => {
  return (
    <>
      <SignedIn>
        <Navigate to={DOWNLOAD_APP_URL} />
      </SignedIn>
      <SignedOut>
        <Navigate to={SIGN_UP_URL} />
      </SignedOut>
    </>
  );
};

export default Index;
